import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../components/Shared'
import About from '../components/About'
import helmetProps from '../components/About/helmetProps'

export const query = graphql`
  {
    thornton: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "Thornton" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    barton: file(relativeDirectory: { eq: "about" }, name: { eq: "Barton" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 200, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gly: file(relativeDirectory: { eq: "about" }, name: { eq: "GLY" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 200, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    anglian: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "AnglianWater" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    corgan: file(relativeDirectory: { eq: "about" }, name: { eq: "Corgan" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ennead: file(relativeDirectory: { eq: "about" }, name: { eq: "Ennead" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    hmc: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "HMC Architects" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    marmon: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "MarmonMok" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    miele: file(relativeDirectory: { eq: "about" }, name: { eq: "Miele" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mortenson: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "Mortenson" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    perkins: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "Perkins and Will" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ramsa: file(relativeDirectory: { eq: "about" }, name: { eq: "Ramsa" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    reed: file(relativeDirectory: { eq: "about" }, name: { eq: "Reed" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    rpbw: file(relativeDirectory: { eq: "about" }, name: { eq: "RPBW" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    scape: file(relativeDirectory: { eq: "about" }, name: { eq: "Scape" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    shop: file(relativeDirectory: { eq: "about" }, name: { eq: "Shop" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    vrGazer: file(relativeDirectory: { eq: "about" }, name: { eq: "1" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 864, maxHeight: 824, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    influentialFirms: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "2" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 980, maxHeight: 980, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    founders: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "5.Founders" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 936, maxHeight: 938, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    team: file(relativeDirectory: { eq: "about" }, name: { eq: "6.Team" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 936, maxHeight: 936, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    emergence: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "Emergence" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    freshTracks: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "Fresh Tracks Capital" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    indicatorVentures: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "Indicator Ventures" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    morningsideVentures: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "Morningside" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    techstars: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "Techstars" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    valar: file(relativeDirectory: { eq: "about" }, name: { eq: "Valar" }) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    pritzkerGroup: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "Pritzker" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 115, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    prospectLogo: file(
      relativeDirectory: { eq: "trial" }
      name: { eq: "start-trial-prospect" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    irisLogo: file(relativeDirectory: { eq: "about" }, name: { eq: "logo" }) {
      id
      name
      childImageSharp {
        fixed(width: 35) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    irisWildLogo: file(
      relativeDirectory: { eq: "logos" }
      name: { eq: "prospect-white" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 180) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    linkedIn: file(
      relativeDirectory: { eq: "about" }
      name: { eq: "Linkedin" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 15) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    twitter: file(relativeDirectory: { eq: "about" }, name: { eq: "Twitter" }) {
      id
      name
      childImageSharp {
        fixed(width: 15) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default memo(props => {
  const data = props.data

  const aboutData = {
    introduction: {
      vrGazer: data.vrGazer,
      irisLogo: data.irisLogo,
      irisWildLogo: data.irisWildLogo,
    },
    firms: {
      influentialFirms: data.influentialFirms,
      firmOverview: {
        thornton: {
          logo: data.thornton,
          url: 'https://www.thorntontomasetti.com/',
        },
        mortenson: { logo: data.mortenson, url: 'https://www.mortenson.com/' },
        corgan: { logo: data.corgan, url: 'https://www.corgan.com/' },
        perkins: { logo: data.perkins, url: 'https://perkinswill.com/' },
        // gly: { logo: data.gly, url: 'https://www.gly.com/' },
        shop: { logo: data.shop, url: 'https://www.shoparc.com/' },
        // ennead: { logo: data.ennead, url: 'http://www.ennead.com/' },
        // hmc: { logo: data.hmc, url: 'https://hmcarchitects.com/' },
        anglian: { logo: data.anglian, url: 'https://www.anglianwater.co.uk/' },
        scape: { logo: data.scape, url: 'https://www.scapestudio.com/' },
        // rpbw: { logo: data.rpbw, url: 'http://www.rpbw.com' },
        barton: { logo: data.barton, url: 'https://www.bartonmalow.com/' },
        // marmon: { logo: data.marmon, url: 'https://marmonmok.com/' },
        // ramsa: { logo: data.ramsa, url: 'http://www.ramsa.com/' },
        // miele: { logo: data.miele, url: 'https://www.mieleusa.com/' },
        reed: { logo: data.reed, url: 'http://www.reedhilderbrand.com/' },
      },
    },
    team: {
      foundersOverview: {
        foundersImage: data.founders,
        socialIcons: {
          linkedInIcon: data.linkedIn,
          twitterIcon: data.twitter,
        },
      },
      teamImage: data.team,
    },
    investorsOverview: {
      emergence: { logo: data.emergence, url: 'https://www.emcap.com/' },
      indicatorVentures: {
        logo: data.indicatorVentures,
        url: 'http://www.indicatorventures.com/',
      },
      techstars: { logo: data.techstars, url: 'https://www.techstars.com/' },
      morningsideVentures: {
        logo: data.morningsideVentures,
        url: 'http://www.morningside.com/',
      },
      valar: { logo: data.valar, url: 'https://valar.com/' },
      freshTracks: {
        logo: data.freshTracks,
        url: 'https://www.freshtrackscap.com/',
      },
      pritzkerGroup: {
        logo: data.pritzkerGroup,
        url: 'https://www.pritzkergroup.com/venture-capital/',
      },
    },
    prospectLogo: data.prospectLogo,
  }

  return (
    <>
      <Helmet {...helmetProps} />
      <About aboutData={aboutData} />
    </>
  )
})
