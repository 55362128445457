import React, { createContext, useState, useEffect } from 'react'
import { Layout } from '../Shared'
import IrisImg from '../../components/Shared/IrisImg'
import AccentBorder from '../Shared/AccentBorder'
import { IrisCTALinkButton, colors } from '../Shared/IrisCTALinkButton'
import PropTypes from 'prop-types'
import { calculatedView } from '../../utils/calculatedView'
import { chunk } from '../../utils/chunk'
import Upgrade from '../Products/Prospect/sections/Upgrade'
import './About.global.scss'

const ViewContext = createContext({ view: 'desktop', isDesktop: true })

const FinalSection = ({ prospectLogo }) => {
  return (
    <ViewContext.Consumer>
      {({ view, isDesktop }) => (
        <div
          style={{
            width: '100%',
            paddingTop: isDesktop ? 107 : 40,
            paddingBottom: isDesktop ? 107 : 40,
            backgroundColor: 'black',
          }}
        >
          <Upgrade logo={prospectLogo} mediaType={view} />
        </div>
      )}
    </ViewContext.Consumer>
  )
}

const CircularHeaderImage = ({ image }) => {
  return (
    <ViewContext.Consumer>
      {({ view }) => (
        <div className={`large-circular-img-${view}`}>
          <IrisImg
            className={`large-circular-img-${view}`}
            file={image}
            alt={`large-circular-img-${view}`}
          />
        </div>
      )}
    </ViewContext.Consumer>
  )
}

const IntroHeader = ({ title, introCopy }) => {
  return (
    <ViewContext.Consumer>
      {({ isDesktop }) => (
        <>
          <div style={{ paddingBottom: 10 }}>
            <AccentBorder color="purple" />
          </div>
          <div
            style={{
              paddingBottom: isDesktop ? 30 : 20,
            }}
          >
            <p style={{ fontSize: isDesktop ? 55 : 34 }}>{title}</p>
          </div>
          <p
            style={{
              fontSize: isDesktop ? 21 : 19,
              color: 'gray',
              paddingBottom: isDesktop ? 0 : 70,
            }}
          >
            {introCopy}
          </p>
        </>
      )}
    </ViewContext.Consumer>
  )
}

const IntroPartOne = ({ vrGazer }) => {
  const title = 'The Natural Next Step for BIM'
  const introCopy =
    'IrisVR empowers teams to host more efficient coordination meetings and design reviews with anyone, anywhere in the world.'
  return (
    <ViewContext.Consumer>
      {({ isDesktop }) => {
        if (!isDesktop) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ paddingBottom: 30 }}>
                <CircularHeaderImage image={vrGazer} />
              </div>
              <IntroHeader title={title} introCopy={introCopy} />
            </div>
          )
        }

        return (
          <div style={{ display: 'flex', paddingBottom: 130 }}>
            <div style={{ paddingRight: 100 }}>
              <IntroHeader title={title} introCopy={introCopy} />
            </div>
            <CircularHeaderImage image={vrGazer} />
          </div>
        )
      }}
    </ViewContext.Consumer>
  )
}

const IntroPartTwo = ({ irisLogo }) => (
  <ViewContext.Consumer>
    {({ view, isDesktop }) => (
      <div style={{ paddingBottom: isDesktop ? 0 : 20 }}>
        <div
          className="background-purple"
          style={{
            paddingTop: isDesktop ? 70 : 60,
            paddingLeft: isDesktop ? 220 : 30,
            paddingRight: isDesktop ? 220 : 30,
            paddingBottom: isDesktop ? 150 : 80,
            textAlign: 'center',
          }}
        >
          <div
            style={
              isDesktop
                ? { paddingBottom: 70 }
                : {
                    paddingBottom: 40,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignContent: 'center',
                  }
            }
          >
            <div className={`iris-wild-logo-${view}`}>
              <IrisImg
                className={`iris-wild-logo-${view}`}
                file={irisLogo}
                alt={`iris-wild-logo-${view}`}
              />
            </div>
          </div>
          <div>
            <p
              className="font-irisvr-light"
              style={{
                color: 'white',
                fontSize: isDesktop ? 30 : 20,
                lineHeight: 2,
              }}
            >
              We give VDC/BIM professionals the VR tools they need to make
              design and model review fast, accurate, and collaborative.
            </p>
          </div>
        </div>
      </div>
    )}
  </ViewContext.Consumer>
)

const Introduction = ({ images }) => {
  const { vrGazer, irisLogo, irisWildLogo } = images

  return (
    <ViewContext.Consumer>
      {({ isDesktop }) => (
        <div
          style={{
            padding: isDesktop ? 100 : 30,
            paddingBottom: isDesktop ? 140 : 60,
          }}
        >
          <IntroPartOne vrGazer={vrGazer} />
          <IntroPartTwo irisLogo={irisWildLogo} />
        </div>
      )}
    </ViewContext.Consumer>
  )
}

const InfluentialStatementText = () => (
  <ViewContext.Consumer>
    {({ isDesktop }) => (
      <div
        style={{
          color: 'white',
          alignSelf: 'center',
          paddingBottom: isDesktop ? 0 : 60,
        }}
      >
        <p
          style={{
            fontSize: isDesktop ? 19 : 16,
            paddingBottom: 40,
            paddingRight: 15,
            ...(isDesktop ? {} : { lineHeight: 1.6 }),
          }}
        >
          We've brought VR to more than 60,000 construction managers, engineers,
          architects, and VDC/BIM teams in 105+ countries across the globe.
          That's why we've taken home trophies
          from <span style={{ fontWeight: 'bold' }}>Build New York</span> and
          the <span style={{ fontWeight: 'bold' }}>Architizer A+ Awards.</span>
        </p>
        <IrisCTALinkButton
          to={'/customers'}
          cta={'Learn about our customers'}
          customStyles={{ backgroundHoverColor: colors.purple }}
        />
      </div>
    )}
  </ViewContext.Consumer>
)

const FirmsPartOne = ({ influentialFirmsStatementImage }) => {
  return (
    <ViewContext.Consumer>
      {({ isDesktop }) => {
        if (!isDesktop) {
          return (
            <>
              <div style={{ display: 'flex', paddingBottom: 50 }}>
                <CircularHeaderImage image={influentialFirmsStatementImage} />
              </div>
              <InfluentialStatementText />
            </>
          )
        }
        return (
          <div style={{ display: 'flex', paddingBottom: 150 }}>
            <div style={{ paddingRight: 100 }}>
              <CircularHeaderImage image={influentialFirmsStatementImage} />
            </div>
            <InfluentialStatementText />
          </div>
        )
      }}
    </ViewContext.Consumer>
  )
}

const FirmsPartTwo = ({ firmOverview }) => {
  return (
    <ViewContext.Consumer>
      {({ view, isDesktop }) => {
        const chunkSize = isDesktop ? 4 : 2

        return chunk(Object.keys(firmOverview), chunkSize).map(
          (firmKeys, idx) => {
            return (
              <div
                key={`firm-row-${idx}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {firmKeys.map((key, i) => {
                  const [logo, url] = [
                    firmOverview[key].logo,
                    firmOverview[key].url,
                  ]
                  return (
                    <div
                      key={`firm-logo-${key}-${i}`}
                      style={{
                        paddingBottom: isDesktop ? 80 : 40,
                      }}
                    >
                      <a href={url} target={'_blank'}>
                        <div className={`firm-logo-${view}`}>
                          <IrisImg
                            className={`firm-logo-${view}`}
                            file={logo}
                            alt={`firm-logo-${view}`}
                          />
                        </div>
                      </a>
                    </div>
                  )
                })}
              </div>
            )
          }
        )
      }}
    </ViewContext.Consumer>
  )
}

const Firms = ({ firms }) => {
  return (
    <ViewContext.Consumer>
      {({ isDesktop }) => (
        <div
          className="background-light-purple"
          style={{
            padding: isDesktop ? 100 : 30,
            paddingTop: isDesktop ? 100 : 50,
            paddingBottom: isDesktop ? 80 : 30,
          }}
        >
          <FirmsPartOne
            influentialFirmsStatementImage={firms.influentialFirms}
          />
          <FirmsPartTwo firmOverview={firms.firmOverview} />
        </div>
      )}
    </ViewContext.Consumer>
  )
}

const SocialLink = ({ url, socialIcon }) => {
  return (
    <ViewContext.Consumer>
      {({ view }) => (
        <a href={url} target={'_blank'}>
          <div className={`social-icon-${view}`}>
            <IrisImg
              className={`social-icon-${view}`}
              file={socialIcon}
              alt={`social-icon-${view}`}
            />
          </div>
        </a>
      )}
    </ViewContext.Consumer>
  )
}

const FounderBlock = ({
  name,
  title,
  linkedInURL,
  twitterURL,
  linkedInIcon,
  twitterIcon,
}) => (
  <div>
    <p style={{ color: 'black', textTransform: 'uppercase', paddingBottom: 5 }}>
      {name}
    </p>
    <p style={{ color: 'gray', paddingBottom: 5 }}>{title}</p>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ paddingRight: 20 }}>
        <SocialLink url={linkedInURL} socialIcon={linkedInIcon} />
      </div>
      <SocialLink url={twitterURL} socialIcon={twitterIcon} />
    </div>
  </div>
)

const founderData = {
  shane: {
    name: 'Shane Scranton',
    title: 'CEO, Co-founder',
    twitterURL: 'https://twitter.com/sscranton',
    linkedInURL: 'https://www.linkedin.com/in/shanescranton/',
  },
  nate: {
    name: 'Nate Beatty',
    title: 'CTO, Co-founder',
    twitterURL: 'https://twitter.com/tnbeatty',
    linkedInURL: 'https://www.linkedin.com/in/natebeatty/',
  },
}

const TeamPartOne = ({ images }) => {
  const { foundersImage, socialIcons } = images

  return (
    <ViewContext.Consumer>
      {({ isDesktop }) => (
        <div
          style={{
            ...(isDesktop ? { display: 'flex' } : {}),
            paddingBottom: isDesktop ? 150 : 60,
          }}
        >
          <div
            style={
              isDesktop
                ? { paddingRight: 100 }
                : { paddingTop: 30, paddingBottom: 40 }
            }
          >
            <CircularHeaderImage image={foundersImage} />
          </div>
          <div style={{ alignSelf: 'center' }}>
            <div style={{ ...(isDesktop ? { paddingBottom: 10 } : {}) }}>
              <AccentBorder color="purple" />
            </div>
            <div
              style={{
                paddingBottom: isDesktop ? 30 : 20,
              }}
            >
              <p style={{ fontSize: isDesktop ? 42 : 34, fontWeight: 'bold' }}>
                Founders
              </p>
            </div>
            <div style={{ display: 'flex', paddingBottom: 40 }}>
              <div style={{ paddingRight: isDesktop ? 150 : 40 }}>
                <FounderBlock {...founderData.shane} {...socialIcons} />
              </div>
              <FounderBlock {...founderData.nate} {...socialIcons} />
            </div>
            <p style={{ fontSize: 16, color: 'gray' }}>
              IrisVR was founded in 2014 after Shane Scranton stepped into VR
              for the first time. Shane had been working within the AEC industry
              and experienced the difficulty of communicating design intent with
              3D models and 2D drawings alone. Convinced that VR allowed for a
              clearer understanding of space and could accelerate the planning
              process, Shane showed his headset to his friend and coworker, Nate
              Beatty. Together, they began building one-click, reliable VR
              design review solutions for the AEC industry—and IrisVR was born.
            </p>
          </div>
        </div>
      )}
    </ViewContext.Consumer>
  )
}

const TeamPartTwoContent = () => {
  return (
    <ViewContext.Consumer>
      {({ isDesktop }) => (
        <>
          <div style={{ ...(isDesktop ? { paddingBottom: 10 } : {}) }}>
            <AccentBorder color="purple" />
          </div>
          <div
            style={{
              paddingBottom: isDesktop ? 30 : 20,
            }}
          >
            <p style={{ fontSize: isDesktop ? 42 : 34, fontWeight: 'bold' }}>
              The IrisVR Team
            </p>
          </div>
          <p
            style={{
              fontSize: 16,
              color: 'gray',
              paddingBottom: 30,
            }}
          >
            Our team consists of VR developers, construction pros, landscape
            architects, UX designers, and many more. Together, we're working to
            create the future of the AEC industry.
          </p>
          <p
            style={{
              fontSize: 16,
              color: 'gray',
              paddingBottom: 40,
            }}
          >
            Interested in working at IrisVR?
          </p>
        </>
      )}
    </ViewContext.Consumer>
  )
}

const TeamPartTwo = ({ teamImage }) => {
  return (
    <ViewContext.Consumer>
      {({ isDesktop }) => {
        if (!isDesktop) {
          return (
            <>
              <div style={{ paddingBottom: 40 }}>
                <CircularHeaderImage image={teamImage} />
              </div>
              <TeamPartTwoContent />
            </>
          )
        }

        return (
          <div style={{ display: 'flex' }}>
            <div style={{ paddingRight: 100, alignSelf: 'center' }}>
              <TeamPartTwoContent />
            </div>
            <CircularHeaderImage image={teamImage} />
          </div>
        )
      }}
    </ViewContext.Consumer>
  )
}

const Team = ({ images }) => (
  <ViewContext.Consumer>
    {({ isDesktop }) => (
      <div
        style={{
          padding: isDesktop ? 100 : 30,
          paddingBottom: isDesktop ? 180 : 60,
        }}
      >
        <TeamPartOne images={images.foundersOverview} />
        <TeamPartTwo teamImage={images.teamImage} />
      </div>
    )}
  </ViewContext.Consumer>
)

const Investors = ({ investorsOverview }) => {
  return (
    <ViewContext.Consumer>
      {({ view, isDesktop }) => {
        const chunkSize = isDesktop ? 3 : 2

        return (
          <div
            style={
              isDesktop
                ? {
                    padding: 120,
                    display: 'flex',
                    flexDirection: 'column',
                  }
                : {
                    padding: 60,
                  }
            }
          >
            <div style={{ textAlign: 'center' }}>
              <p
                style={{
                  fontSize: isDesktop ? 42 : 34,
                  color: 'white',
                  paddingBottom: isDesktop ? 90 : 50,
                }}
              >
                Our Investors
              </p>
            </div>
            {chunk(Object.keys(investorsOverview), chunkSize).map(
              (investorKeys, idx) => {
                const isLastRow = isDesktop ? idx === 2 : idx === 3
                return (
                  <div
                    style={
                      isDesktop
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                          }
                        : {
                            display: 'flex',
                            alignItems: isLastRow ? 'center' : 'flex-start',
                            justifyContent: 'space-around',
                          }
                    }
                    key={`investor-row-${idx}`}
                  >
                    {investorKeys.map((key, i) => {
                      const [logo, url] = [
                        investorsOverview[key].logo,
                        investorsOverview[key].url,
                      ]
                      const firstColumn = i === 0

                      return (
                        <div
                          key={`investor-logo-${key}-${i}`}
                          style={
                            isDesktop
                              ? {
                                  paddingBottom: isLastRow ? 0 : 100,
                                }
                              : {
                                  paddingRight:
                                    firstColumn && !isLastRow ? 40 : 0,
                                  paddingBottom: isLastRow ? 0 : 60,
                                }
                          }
                        >
                          <a href={url} target={'_blank'}>
                            <div className={`investor-logo-${view}`}>
                              <IrisImg
                                className={`investor-logo-${view}`}
                                file={logo}
                                alt={`investor-logo-${view}`}
                              />
                            </div>
                          </a>
                        </div>
                      )
                    })}
                  </div>
                )
              }
            )}
          </div>
        )
      }}
    </ViewContext.Consumer>
  )
}

const SectionContainer = ({ additionalClasses = '', children }) => {
  return (
    <ViewContext.Consumer>
      {({ view }) => (
        <div
          className={additionalClasses}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className={`content-container-${view}`}>{children}</div>
        </div>
      )}
    </ViewContext.Consumer>
  )
}

const About = props => {
  const { width, aboutData } = props
  const [view, setView] = useState('desktop')

  useEffect(() => {
    setView(calculatedView(width))
  }, [width])

  const isDesktop = view === 'desktop'

  return (
    <div id="about-page">
      <ViewContext.Provider value={{ view, isDesktop }}>
        <SectionContainer>
          <Introduction images={aboutData.introduction} />
        </SectionContainer>
        <SectionContainer additionalClasses={'background-light-purple'}>
          <Firms firms={aboutData.firms} />
        </SectionContainer>
        <FinalSection prospectLogo={aboutData.prospectLogo} />
      </ViewContext.Provider>
    </div>
  )
}

About.propTypes = {
  aboutData: PropTypes.exact({
    introduction: PropTypes.exact({
      vrGazer: PropTypes.exact({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.shape(),
          fluid: PropTypes.shape(),
        }),
      }),
      irisLogo: PropTypes.exact({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.shape(),
          fluid: PropTypes.shape(),
        }),
      }),
    }),
    firms: PropTypes.exact({
      influentialFirms: PropTypes.exact({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.shape(),
          fluid: PropTypes.shape(),
        }),
      }),
      firmOverview: PropTypes.objectOf(
        PropTypes.exact({
          logo: PropTypes.exact({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            childImageSharp: PropTypes.shape({
              fixed: PropTypes.shape(),
              fluid: PropTypes.shape(),
            }),
          }),
          url: PropTypes.string.isRequired,
        })
      ),
    }),
    team: PropTypes.exact({
      foundersOverview: PropTypes.exact({
        foundersImage: PropTypes.exact({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          childImageSharp: PropTypes.shape({
            fixed: PropTypes.shape(),
            fluid: PropTypes.shape(),
          }),
        }),
        socialIcons: PropTypes.exact({
          linkedInIcon: PropTypes.exact({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            childImageSharp: PropTypes.shape({
              fixed: PropTypes.shape(),
              fluid: PropTypes.shape(),
            }),
          }),
          twitterIcon: PropTypes.exact({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            childImageSharp: PropTypes.shape({
              fixed: PropTypes.shape(),
              fluid: PropTypes.shape(),
            }),
          }),
        }),
      }),
      teamImage: PropTypes.exact({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.shape(),
          fluid: PropTypes.shape(),
        }),
      }),
    }),
    investorsOverview: PropTypes.objectOf(
      PropTypes.exact({
        logo: PropTypes.exact({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          childImageSharp: PropTypes.shape({
            fixed: PropTypes.shape(),
            fluid: PropTypes.shape(),
          }),
        }),
        url: PropTypes.string.isRequired,
      })
    ),
    prospectLogo: PropTypes.exact({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.shape(),
        fluid: PropTypes.shape(),
      }),
    }),
  }),
}

export default props => {
  return (
    <Layout>
      <About {...props} />
    </Layout>
  )
}
