import { getWindowLocationOrigin } from '../../utils/window'
import { generateMetaTags } from '../../utils/helmet'

const title = 'About | IrisVR'
const description = `IrisVR is building VR tools for AEC professionals—it's the natural next step for BIM. Learn how we got here and where we plan to go.`
const url = `${getWindowLocationOrigin()}/about`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Quest.jpg.png'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
